import Domain from '@/types/Domain';


interface Props {
  name: string;
  image: {contentUrl: string};
}

class Tag extends Domain implements Props {
  public static createAll(data: any[]): Tag[] {
    return data.map(Tag.create);
  }

  public static create(data: any): Tag {
    return new Tag(
      data.id,
      data.name,
      data.image,
    );
  }

  public static createDefault(): Tag {
    return new Tag('', '', undefined);
  }

  private constructor(
    public id: string,
    public name: string,
    public image: {contentUrl: string},
  ) {
    super();
  }

  public clone(): Tag {
    const clone = JSON.parse(JSON.stringify(this));
    return Tag.create({
      ...clone
    });
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Name darf nicht leer sein',
        },
        format: {
          pattern: /^[^#"'&$,;|=]{2,255}$/,
          message: '^Dieses Feld muss zwischen 2 und 255 Zeichen lang sein. Folgende Zeichen sind NICHT zulässig: #"\'&$,;|=',
        },
      },
      image: {
        presence: {
          message: '^Es muss ein Bild hinterlegt werden',
        },
      },
    };
  }
}

export default Tag;
