import fetchList, { FetchListPayload, FetchListAction } from '@/utils/api-platform/fetchList';
import Tag from '@/domains/tag/Tag';


const fetchTagList: FetchListAction<Tag> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/tags`,
    domainParser: Tag.create,
    listMutation: 'setTags',
    listInfoMutation: 'setTagListInfo',
  });
};

export default fetchTagList;
