import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import ArticleImage from './ArticleImage';
import ArticleVariant from './ArticleVariant';
import fillArray from '@/utils/fillArray';
import sortByProp from '@/utils/sortByProp';
import { notWhitespacesOnly } from '@/utils/validations';

interface Props {
  active: boolean;
  bulletPoints: string[];
  categoryId: string;
  tagIds: string[];
  categoryTagId: string;
  description: string;
  id: string;
  articleImages: ArticleImage[];
  mandantId: string;
  manufacturerId: string;
  name: string;
  articlePrice: Money;
  taxRate: number;
  unitFee: Money;
  variants: ArticleVariant[];
  recommendedRetailPrice?: Money;
  articleInfoDocument?: {
    contentUrl: string;
  };
  documentUrl?: string;
  categoryShortcut?: string;
}

class Article extends Domain implements Props {
  public static MAX_BULLET_POINTS_AMOUNT: number = 5;

  public static createAll(articles: any[]): Article[] {
    return articles.map(Article.create);
  }

  public static create(data: any): Article {
    return new Article(
      data.active,
      fillArray(data.bulletPoints || [], Article.MAX_BULLET_POINTS_AMOUNT, ''),
      data.categoryId || data.categorySpecificPortfolio?.id || '',
      data.tagIds || data.tags,
      data.categoryTagId || '',
      data.description,
      data.id,
      sortByProp(
        ArticleImage.createAll(data.images || data.articleImages || []),
        'order'
      ),
      data.mandantId,
      data.manufacturerId || data.manufacturer?.id || '',
      data.name,
      Money.create(data.articlePrice || {}),
      data.taxRate || 0,
      Money.create(data.unitFee || {}),
      ArticleVariant.createAll(data.variants || []),
      Money.create({ amount: data.recommendedRetailPrice } || {}),
      data.articleInfoDocument || data.documentUrl,
      (data.categorySpecificPortfolio &&
        data.categorySpecificPortfolio.shortcut) ||
        ''
    );
  }

  public static createDefault(): Article {
    return Article.create({
      active: false,
      description: '',
      name: '',
    });
  }

  private constructor(
    public active: boolean,
    public bulletPoints: string[],
    public categoryId: string,
    public tagIds: string[],
    public categoryTagId: string,
    public description: string,
    public id: string,
    public articleImages: ArticleImage[],
    public mandantId: string,
    public manufacturerId: string,
    public name: string,
    public articlePrice: Money,
    public taxRate: number,
    public unitFee: Money,
    public variants: ArticleVariant[],
    public recommendedRetailPrice?: Money,
    public articleInfoDocument?: {
      contentUrl: string;
    },
    public categoryShortcut?: string
  ) {
    super();
  }

  public constraints(): {} {
    const bulletPointConstraints = {};
    for (let i = 0; i < Article.MAX_BULLET_POINTS_AMOUNT; i++) {
      bulletPointConstraints[`bulletPoints.${i}`] = {
        format: {
          pattern: /^$|^.{3,80}$/,
          message: '^Ein Bullet Point muss zwischen 3 und 80 Zeichen lang sein',
        },
      };
    }

    return {
      ...bulletPointConstraints,
      'articleInfoDocument.name': {
        format: {
          pattern: /^.*\.(pdf|PDF)$/,
          message: '^Die Datei ist nicht vom hier erlaubten Typ, pdf.',
        },
      },
      'articleInfoDocument.size': {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 10489000,
          message: '^Die Datei ist zu groß. Erlaubt sind maximal 10MB.',
        },
      },
      categoryId: {
        presence: {
          message: '^Kategorie ist ein Pflichtfeld',
        },
      },
      description: {
        presence: {
          message: '^Beschreibung ist ein Pflichtfeld',
        },
        format: {
          pattern: /^(.|\r\n|\r|\n){3,10000}$/,
          message: '^Beschreibung muss zwischen 3 und 10.000 Zeichen lang sein',
        },
      },
      articleImages: {
        length: {
          maximum: 3,
          message: '^Es dürfen maximal 3 Bilder hochgeladen werden',
        },
      },
      mandantId: {
        presence: false,
        format: {
          pattern: notWhitespacesOnly,
          message: '^Mandant ist ein Pflichtfeld',
        },
      },
      manufacturerId: {
        presence: {
          message: '^Hersteller ist ein Pflichtfeld',
        },
      },
      name: {
        presence: {
          message: '^Artikelbezeichnung ist ein Pflichtfeld',
        },
        format: {
          pattern: /^.{2,100}$/,
          message:
            '^Artikelbezeichnung muss zwischen 2 und 100 Zeichen lang sein',
        },
      },
      articlePrice: {
        presence: {
          message: '^VK B2B ist ein Pflichtfeld',
        },
      },
      'articlePrice.amount': {
        numericality: {
          onlyInteger: true,
          greaterThan: 0,
          notGreaterThan: '^VK B2B muss größer als 0 sein',
        },
      },
      taxRate: {
        presence: {
          message: '^Steuersatz ist ein Pflichtfeld',
        },
      },
      unitFee: {
        presence: {
          message: '^Handlingspauschale ist ein Pflichtfeld',
        },
      },
      'unitFee.amount': {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo:
            '^Handlingspauschale darf nicht negativ sein',
        },
      },
      recommendedRetailPrice: {
        presence: false,
      },
      'recommendedRetailPrice.amount': {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 0,
          notGreaterThanOrEqualTo: '^UVP darf nicht negativ sein',
        },
      },
      variants: {
        presence: {
          message: '^Es muss mindestens 1 Variante angelegt werden',
        },
      },
    };
  }

  public clone(): Article {
    const clone = JSON.parse(JSON.stringify(this));
    return Article.create({
      ...clone,
      articleImages: this.articleImages.map((image) => image.clone()),
      variants: this.variants.map((variant) => variant.clone()),
    });
  }

  public toJsonData() {
    const mapImages = (images: ArticleImage[]) => {
      return images.map((image) => ({
        order: image.order,
        image: image.isNew ? image.image.id : undefined,
        id: !image.isNew ? image.id : undefined,
        description: image.description || null,
      }));
    };
    const articleVariants = this.variants.map((variant) => {
      const bulletPointsVariant = variant.bulletPoints.reduce(
        (acc: string[], cur) => {
          if (cur.length) {
            acc.push(cur);
          }
          return acc;
        },
        []
      );

      return {
        id: variant.id,
        active: variant.active,
        description: variant.description,
        mkEbootisId: variant.mkEbootisId,
        ekEbootisId: variant.ekEbootisId,
        name: variant.name,
        endOfLife: variant.endOfLife,
        color: {
          colorName: variant.color.colorName,
          colorCode: variant.color.colorCode,
        },
        bulletPoints: bulletPointsVariant,
        variantImages: mapImages(variant.variantImages),
      };
    });

    const bulletPoints = this.bulletPoints.reduce((acc: string[], cur) => {
      if (cur.length) {
        acc.push(cur);
      }
      return acc;
    }, []);

    const data: Record<string, any> = {
      active: this.active,
      description: this.description,
      categorySpecificPortfolio: this.categoryId,
      manufacturer: `/api/manufacturers/${this.manufacturerId}`,
      mandant: this.mandantId,
      name: this.name,
      articlePrice: {
        amount: this.articlePrice.amount,
        currency: this.articlePrice.currency,
      },
      taxRate: Number(this.taxRate),
      unitFee: {
        amount: this.unitFee.amount,
        currency: this.unitFee.currency,
      },
      recommendedRetailPrice: this.recommendedRetailPrice.amount || null,
      tags: this.tagIds,
      bulletPoints,
      articleInfoDocument: this.articleInfoDocument,
      articleImages: mapImages(this.articleImages),
      variants: articleVariants,
    };

    return data;
  }

  public getArticleImages(variantId: string): ArticleImage[] {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (variantId && variant && variant.variantImages.length > 0) {
      return variant.variantImages;
    } else {
      return this.articleImages;
    }
  }

  public getBulletPoints(variantId: string): string[] {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (
      variantId &&
      variant &&
      variant.bulletPoints.find((item) => item !== '')
    ) {
      return variant.bulletPoints;
    } else {
      return this.bulletPoints;
    }
  }

  public getDescription(variantId: string): string {
    const variant = this.variants.find((item) => {
      return item.id === variantId;
    });

    if (variantId && variant && variant.description) {
      return variant.description;
    } else {
      return this.description;
    }
  }
}

export default Article;
