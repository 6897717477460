import Category from '@/domains/category/Category';
import fetchList, {
  FetchListPayload,
  FetchListAction,
} from '@/utils/api-platform/fetchList';

const fetchCategoryList: FetchListAction<Category> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: '/category-portfolios',
    domainParser: Category.create,
    listMutation: 'setCategories',
    listInfoMutation: 'setCategoryListInfo',
  });
};

export default fetchCategoryList;
