import { GetterTree } from 'vuex';
import { CampaignWizardState } from './state';
import NormalizedData from '@/types/NormalizedData';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import FreeTextField from '@/domains/freeTextField/FreeTextField';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';
import {GeneralSettings} from '@/types/campaign/GeneralSettings';


export const getSelectedCampaignArticles = (state: CampaignWizardState): NormalizedData<CampaignArticle> => {
  return state.selectedCampaignArticles;
};
export const getSortedSelectedCampaignArticles = (state: CampaignWizardState): CampaignArticle[]=> {
  return Object.values(state.selectedCampaignArticles).sort((a, b)=> a.position-b.position);
};

// @ts-ignore
export const getGeneralSettings = (state: CampaignWizardState): GeneralSettings => state.generalSettings;

export const getCodeType = (state: CampaignWizardState): string => state.codeType;
export const getCampaignCodeType = (state: CampaignWizardState): string => state.codeProperties.type.id;


export const getTokenDistributionType = (state: CampaignWizardState): string => state.tokenDistributionType;

export const getGenericLimited = (state: CampaignWizardState): boolean => state.genericLimited;

export const getCodeProperties = (state: CampaignWizardState): CampaignCodeProperties => state.codeProperties;
export const getVerificationType = (state: CampaignWizardState): string => state.verificationType;
export const getApprovalMailRepeat = (state: CampaignWizardState): string => state.approvalMailRepeat;

export const getAdditionalInfoEnabled = (state: CampaignWizardState): boolean => state.additionalInfoEnabled;
export const getFileUploadEnabled = (state: CampaignWizardState): boolean => state.fileUploadEnabled;
export const getRedemptionDelayEnabled = (state: CampaignWizardState): boolean => state.redemptionDelayEnabled;
export const getTextFieldsEnabled = (state: CampaignWizardState): boolean => state.textFieldsEnabled;
export const getTextFields = (state: CampaignWizardState): FreeTextField[] => state.textFields;
export const getColumnInformations = (state: CampaignWizardState): FreeTextField[] => state.columnInformations;
export const getCustomMessagesEnabled = (state: CampaignWizardState): boolean => state.customMessagesEnabled;
export const getCloneId = (state: CampaignWizardState): string => state.cloneId;
export const getAccessImagePreview = (state: CampaignWizardState): string => state.accessImagePreview;

const getters: GetterTree<CampaignWizardState, any> = {
  getCustomMessagesEnabled,
  getGeneralSettings,
  getSelectedCampaignArticles,
  getSortedSelectedCampaignArticles,
  getCodeType,
  getCampaignCodeType,
  getGenericLimited,
  getCodeProperties,
  getVerificationType,
  getTokenDistributionType,
  getAdditionalInfoEnabled,
  getFileUploadEnabled,
  getRedemptionDelayEnabled,
  getTextFieldsEnabled,
  getTextFields,
  getColumnInformations,
  getApprovalMailRepeat,
  getCloneId,
  getAccessImagePreview
};

export default getters;
