import Domain from '@/types/Domain';

interface Props {
  id: string;
  file: File;
  type: string;
  contentUrl?: string;
}

class MediaObject extends Domain implements Props {

  public static create(data: any): MediaObject {
    return new MediaObject(
      data.id,
      data.file,
      data.type,
      data.contentUrl
    );
  }

  private constructor(
    public id: string,
    public file: File,
    public type: string,
    public contentUrl: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add contraints
    };
  }

  public clone(): MediaObject {
    return MediaObject.create({
      ...JSON.parse(JSON.stringify(this)),
    });
  }
}

export default MediaObject;
