import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import Color from '@/domains/common/Color';
import ArticleImage from './ArticleImage';
import fillArray from '@/utils/fillArray';
import sortByProp from '@/utils/sortByProp';

type WeightUnit = 'g' | 'kg';

interface Props {
  active: boolean;
  id: string;
  ekEbootisId: string;
  mkEbootisId: string;
  name: string;
  endOfLife: string; // needs to be date.
  color: Color;
  bulletPoints: string[];
  description: string;
  variantImages: ArticleImage[];
}

class ArticleVariant extends Domain implements Props {
  public static MAX_BULLET_POINTS_AMOUNT: number = 5;

  public static createAll(articles: any[]): ArticleVariant[] {
    return articles.map(ArticleVariant.create);
  }

  public static create(data: any): ArticleVariant {
    // console.log(sortByProp(data.variantImages || [], 'order', 'DESC'));
    return new ArticleVariant(
      data.active,
      data.id,
      data.ekEbootisId,
      data.mkEbootisId,
      data.name,
      data.endOfLife?.slice(0, 10),
      data.color || Color.create({}),
      fillArray(
        data.bulletPoints || [],
        ArticleVariant.MAX_BULLET_POINTS_AMOUNT,
        ''
      ),
      data.description,
      sortByProp(
        ArticleImage.createAll(data.images || data.variantImages || []),
        'order'
      )
    );
  }

  private constructor(
    public active: boolean,
    public id: string,
    public ekEbootisId: string,
    public mkEbootisId: string,
    public name: string,
    public endOfLife: string,
    public color: Color,
    public bulletPoints: string[],
    public description: string,
    public variantImages: ArticleImage[]
  ) {
    super();
  }

  public constraints(): {} {
    const bulletPointConstraints = {};
    for (let i = 0; i < ArticleVariant.MAX_BULLET_POINTS_AMOUNT; i++) {
      bulletPointConstraints[`bulletPoints.${i}`] = {
        format: {
          pattern: /^$|^.{3,80}$/,
          message: '^Ein Bullet Point muss zwischen 3 und 80 Zeichen lang sein',
        },
      };
    }

    return {
      ...bulletPointConstraints,
      color: {
        presence: true,
      },
      'color.colorCode': {
        presence: {
          message: '^Farbcode ist ein Pflichtfeld',
        },
        format: {
          pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
          message: '^Der Farbcode muss im Hex-Format sein',
        },
      },
      'color.colorName': {
        presence: {
          message: '^Gerätefarbe ist ein Pflichtfeld',
        },
      },
      description: {
        format: {
          pattern: /^$|^(.|\r\n|\r|\n){3,10000}$/,
          message: '^Beschreibung muss zwischen 3 und 10.000 Zeichen lang sein',
        },
      },
      ekEbootisId: {
        presence: {
          message: '^EK-ebootis-ID ist ein Pflichtfeld',
        },
        format: {
          pattern: /^[a-zA-Z0-9\-]{1,12}$/,
          message:
            '^EK-ebootis-ID muss aus 1 - 12 alphanumerischen Zeichen bestehen',
        },
      },
      mkEbootisId: {
        presence: {
          message: '^MK-ebootis-ID ist ein Pflichtfeld',
        },
        format: {
          pattern: /^[a-zA-Z0-9\-]{1,12}$/,
          message:
            '^MK-ebootis-ID muss aus 1 - 12 alphanumerischen Zeichen bestehen',
        },
      },
      endOfLife: {
        presence: {
          message: '^EOL ist ein Pflichtfeld',
        },
      },
      variantImages: {
        length: {
          maximum: 3,
          message: '^Es dürfen maximal 3 Bilder hochgeladen werden',
        },
      },
      name: {
        presence: {
          message: '^Artikelbezeichnung ist ein Pflichtfeld',
        },
      },
    };
  }

  public clone(): ArticleVariant {
    const clone = JSON.parse(JSON.stringify(this));
    return ArticleVariant.create({
      ...JSON.parse(JSON.stringify(this)),
      variantImages: clone.variantImages.map((image, index) => ({
        ...image,
      })),
    });
  }
}

export default ArticleVariant;
