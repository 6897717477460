import NormalizedData from '@/types/NormalizedData';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import FreeTextField from '@/domains/freeTextField/FreeTextField';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';
import {GeneralSettings} from '@/types/campaign/GeneralSettings';
import placeHolderImage from '@/assets/placeholder.svg';


export interface CampaignWizardState {
  cloneId?: string;
  customMessagesEnabled: boolean;
  generalSettings: GeneralSettings;
  selectedCampaignArticles: NormalizedData<CampaignArticle>;
  codeType: string;
  codeProperties: CampaignCodeProperties;
  tokenDistributionType: string;
  verificationType: string;
  genericLimited: boolean;
  additionalInfoEnabled: boolean;
  fileUploadEnabled: boolean;
  redemptionDelayEnabled: boolean;
  textFieldsEnabled: boolean;
  textFields: FreeTextField[];
  columnInformations: FreeTextField[];
  approvalMailRepeat: string;
  accessImagePreview: string;
}

const state: CampaignWizardState = {
  cloneId: undefined,
  customMessagesEnabled: false,
  generalSettings: {
    name: undefined,
    startDate: null,
    endDate: null,
    active: false,
    costCenter: undefined,
    externalId: undefined,
  },
  selectedCampaignArticles: {},
  codeType: '',
  codeProperties: CampaignCodeProperties.create({}),
  tokenDistributionType: 'DOWNLOAD',
  verificationType: '',
  genericLimited: false,
  additionalInfoEnabled: false,
  fileUploadEnabled: false,
  redemptionDelayEnabled: false,
  textFieldsEnabled: false,
  textFields: [],
  columnInformations: [],
  approvalMailRepeat: '',
  accessImagePreview: placeHolderImage
};

export default state;
