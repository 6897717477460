import pushSingle, {
  PushSingleAction,
  PushSinglePayload,
} from '@/utils/api-platform/pushSingle';
import HighlightRank from '@/domains/highlight/HighlightRank';

const createHighlights: PushSingleAction<HighlightRank> = async (
  { commit, dispatch },
  payload: PushSinglePayload<HighlightRank>
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/highlights`,
    mutation: undefined,
  });
};

export default createHighlights;
