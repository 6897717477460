import { MutationTree } from 'vuex';
import { CampaignWizardState } from './state';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import normalizeData from '@/utils/normalizeData';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';
import FreeTextField from '@/domains/freeTextField/FreeTextField';
import {GeneralSettings} from '@/types/campaign/GeneralSettings';
import NormalizedData from '@/types/NormalizedData';
import Campaign from '@/domains/campaign/Campaign';
import placeHolderImage from '@/assets/placeholder.svg';

const updateGeneralSettings = (
  state: CampaignWizardState,
  // @ts-ignore
  generalSettings: GeneralSettings,
): void => {
  state.generalSettings = {
    ...state.generalSettings,
    ...generalSettings,
  };
};

const clearCampaignWizardState = (
  state: CampaignWizardState,
): void => {
  state.cloneId = undefined;
  state.generalSettings = {
    name: undefined,
    startDate: null,
    endDate: null,
    active: false,
    costCenter: undefined,
    externalId: undefined,
  };
  state.selectedCampaignArticles = {};
  state.codeType = '';
  state.codeProperties = CampaignCodeProperties.create({});
  state.tokenDistributionType = 'DOWNLOAD';
  state.verificationType = '';
  state.genericLimited = false;
  state.additionalInfoEnabled = false;
  state.fileUploadEnabled = false;
  state.redemptionDelayEnabled = false;
  state.textFieldsEnabled = false;
  state.textFields = [];
  state.approvalMailRepeat = '';
  state.customMessagesEnabled = false;
  state.columnInformations = [];
  state.accessImagePreview = placeHolderImage;
};

const toggleSelectedCampaignArticle = (
  state: CampaignWizardState,
  campaignArticle: CampaignArticle,
): void => {
  const id = campaignArticle.id;
  if (state.selectedCampaignArticles.hasOwnProperty(id)) {
    delete state.selectedCampaignArticles[id];
    state.selectedCampaignArticles = {
      ...state.selectedCampaignArticles as any as NormalizedData<CampaignArticle>
    };
  } else {
    const normalizedCampaignArticle = normalizeData([campaignArticle]);
    state.selectedCampaignArticles = {
      ...state.selectedCampaignArticles as any as NormalizedData<CampaignArticle>,
      ...normalizedCampaignArticle as any as NormalizedData<CampaignArticle>,
    };
  }
};

const updateSelectedCampaignArticle = (
  state: CampaignWizardState,
  campaignArticle: CampaignArticle,
): void => {
  const normalizedCampaignArticle = normalizeData([campaignArticle]);
  state.selectedCampaignArticles = {
    ...state.selectedCampaignArticles as any as NormalizedData<CampaignArticle>,
    ...normalizedCampaignArticle as any as NormalizedData<CampaignArticle>,
  };
};

export const updateSelectedCampaignArticlePosition = (
  state: CampaignWizardState,
  campaignArticle: CampaignArticle,
): void => {
  if(state.selectedCampaignArticles[campaignArticle.id]){
    state.selectedCampaignArticles[campaignArticle.id].position = campaignArticle.position;
  } 
};

export const setCodeType = (
  state: CampaignWizardState,
  codeType: string,
): void => {
  state.codeType = codeType;
};

const setCodeProperties = (
  state: CampaignWizardState,
  codeProperties: any,
): void => {
  state.codeProperties = {
    ...state.codeProperties,
    ...codeProperties,
  };
};

export const setTokenDistributionType = (
  state: CampaignWizardState,
  distributionType: string,
): void => {
  state.tokenDistributionType = distributionType;
};

export const setVerificationType = (
  state: CampaignWizardState,
  verificationType: string,
): void => {
  state.verificationType = verificationType;
};

export const setApprovalMailRepeat = (
  state: CampaignWizardState,
  approvalMail: string,
): void => {
  state.approvalMailRepeat = approvalMail;
};

export const setGenericLimited = (
  state: CampaignWizardState,
  genericLimited: boolean,
): void => {
  state.genericLimited = genericLimited;
};

export const setAdditionalInfoEnabled = (
  state: CampaignWizardState,
  enabled: boolean,
): void => {
  state.additionalInfoEnabled = enabled;
};

export const setFileUploadEnabled = (
  state: CampaignWizardState,
  enabled: boolean,
): void => {
  state.fileUploadEnabled = enabled;
};

export const setRedemptionDelayEnabled = (
  state: CampaignWizardState,
  enabled: boolean,
): void => {
  state.redemptionDelayEnabled = enabled;
};

export const setTextFieldsEnabled = (
  state: CampaignWizardState,
  enabled: boolean,
): void => {
  state.textFieldsEnabled = enabled;
};

export const setCustomMessagesEnabled = (
  state: CampaignWizardState,
  enabled: boolean,
): void => {
  state.customMessagesEnabled = enabled;
};

export const setTextFields = (
  state: CampaignWizardState,
  textFields: FreeTextField[],
): void => {
  state.textFields = textFields;
};

export const addTextField = (
  state: CampaignWizardState,
  textField: FreeTextField,
): void => {
  state.textFields = state.textFields.concat([textField]);
};

export const setColumnInformations = (
  state: CampaignWizardState,
  textFields: FreeTextField[],
): void => {
  state.columnInformations = textFields;
};

export const addColumnInformations = (
  state: CampaignWizardState,
  textField: FreeTextField,
): void => {
  state.columnInformations = state.columnInformations.concat([textField]);
};

export const removeTextField = (
  state: CampaignWizardState,
  index: number,
): void => {
  state.textFields = [...state.textFields.slice(0, index), ...state.textFields.slice(index + 1)];
};

export const setSelectedCampaignArticles = (
  state: CampaignWizardState,
  articleList: NormalizedData<CampaignArticle>,
): void => {
  state.selectedCampaignArticles = articleList;
};

export const setAccessImagePreview = (
  state: CampaignWizardState,
  accessImagePreview: string,
): void => {
  state.accessImagePreview = accessImagePreview;
};

export const loadCampaign = (
  state: CampaignWizardState,
  clone: Campaign
): void => {
  // TODO: this maybe should be reflected in BE model
  let wizardCodetype: string;

  if (clone.codeProperties.type.id === 'GENERIC_UNLIMITED' || clone.codeProperties.type.id === 'GENERIC_LIMITED') {
    wizardCodetype = 'GENERIC'
  } else if (clone.campaignType === 'CAMPAIGN_ACCESS') {
    wizardCodetype = 'ACCESS'
  } else {
    wizardCodetype = 'INDIVIDUAL'
  }

  state.cloneId = clone.id;
  state.generalSettings = {
    name: clone.name+' [ACHTUNG: KOPIE!]',
    startDate: null,
    endDate: null,
    active: false,
    costCenter: clone.costCenter,
    externalId: '',
  }
  state.selectedCampaignArticles = {};
  state.codeType = wizardCodetype;
  state.genericLimited = (clone.codeProperties.type.id === 'GENERIC_LIMITED');
  state.codeProperties = CampaignCodeProperties.create(clone.codeProperties);
  state.tokenDistributionType = clone.tokenDistributionType;
  state.verificationType = clone.verification;
  state.additionalInfoEnabled = !!clone.additionalInfo.additionalInfoText;
  state.fileUploadEnabled = clone.fileUploadActive;
  state.redemptionDelayEnabled = !!clone.salesProperties.redemptionDelay;
  state.textFieldsEnabled = !!clone.customerFields.length;
  state.textFields = clone.customerFields.map((textField: any) => FreeTextField.fromCampaignCustomerField(textField));
  state.approvalMailRepeat = clone.approvalMail;
  state.customMessagesEnabled = !!clone.salesProperties.messagingProperties.email.text.templateText;
  state.columnInformations = clone.accessProperties.columnInformations.map((textField: any) =>
    FreeTextField.fromCampaignCustomerField(textField));
}

const mutations: MutationTree<CampaignWizardState> = {
  setCustomMessagesEnabled,
  updateGeneralSettings,
  clearCampaignWizardState,
  toggleSelectedCampaignArticle,
  setSelectedCampaignArticles,
  updateSelectedCampaignArticle,
  updateSelectedCampaignArticlePosition,
  setCodeType,
  setCodeProperties,
  setVerificationType,
  setApprovalMailRepeat,
  setTokenDistributionType,
  setGenericLimited,
  setAdditionalInfoEnabled,
  setFileUploadEnabled,
  setRedemptionDelayEnabled,
  setTextFieldsEnabled,
  setTextFields,
  addTextField,
  setColumnInformations,
  addColumnInformations,
  removeTextField,
  loadCampaign,
  setAccessImagePreview
};

export default mutations;
