import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import CampaignArticle from './CampaignArticle';

interface Props {
  coPaymentGrossC2B: Money;
  position: number;
  active: boolean;
}

class CampaignArticleEdit extends Domain implements Props {
  public static createAll(data: any[]): CampaignArticleEdit[] {
    return data.map(CampaignArticleEdit.create);
  }

  public static create(data: any): CampaignArticleEdit {
    return new CampaignArticleEdit(
      Money.create(data.coPaymentGrossC2B || {}),
      data.position,
      data.active
    );
  }

  public static createFromCampaignArticle(data: CampaignArticle): CampaignArticleEdit {
    return CampaignArticleEdit.create({
      coPaymentGrossC2B: data.coPaymentGrossC2B,
      position: data.position,
      active: data.active
    })
  }

  private constructor(
    public coPaymentGrossC2B: Money,
    public position: number,
    public active: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): CampaignArticleEdit {
    return CampaignArticleEdit.create(this);
  }
}

export default CampaignArticleEdit;
