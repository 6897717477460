import Article from '@/domains/article/Article';
import pushSingle, {
  PushSingleAction,
  PushSinglePayload,
} from '@/utils/api-platform/pushSingle';

const createArticle: PushSingleAction<Article> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Article>
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/articles`,
    mutation: 'addArticles',
  });
};

export default createArticle;
