import FetchFn from '@/types/FetchFn';
import {
  HttpMethod,
  post,
  put,
  patch,
  del,
} from '@/utils/api-platform/doRequest';
import Domain from '@/types/Domain';
import normalizeData from '@/utils/normalizeData';
import Article from '@/domains/article/Article';
import scrollToFirstErroneousElement from '@/utils/scrollToFirstErroneousElement';
import handleAPIValidationsWithDomain from '@/utils/api-platform/handleAPIValidationsWithDomain';
import Campaign from '@/domains/campaign/Campaign';
import restify from '@/utils/api-platform/restify';
import Tag from '@/domains/tag/Tag';
import CampaignEdit from '@/domains/campaign/CampaignEdit';
import Message from '@/domains/message/Message';
import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import MediaObject from '@/domains/mediaObject/MediaObject';
import RefreshSession from '@/domains/auth/RefreshSession';

export interface PushSinglePayload<T extends Domain> {
  fetch: FetchFn;
  domain: T;
  shouldRequestRefreshToken?: boolean;
  suppressClientErrorPopups?: boolean;
}

export interface PushSingleMandantSpecificPayload<T extends Domain>
  extends PushSinglePayload<T> {
  mandantId: string;
}

export interface ExtendedPushSinglePayload {
  url: string;
  mutation: string;
}

export type PushSingleAction<T extends Domain> = (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<T>
) => Promise<any>;

const pushSingle = async <T extends Domain>(
  { commit, dispatch },
  method: HttpMethod,
  {
    fetch,
    domain,
    shouldRequestRefreshToken = true,
    suppressClientErrorPopups = false,
  }: PushSinglePayload<T>,
  { url, mutation }: ExtendedPushSinglePayload
): Promise<any> => {
  if (!(domain instanceof Domain)) {
    throw new Error('Payload is not of type Domain');
  }

  // TODO: REMOVE after CampaignArticle refactoring!!!
  if (!(domain instanceof Campaign)) {
    if (!domain.validate()) {
      //console.warn(domain);
      scrollToFirstErroneousElement();
      throw new Error('Domain invalid');
    }
  }

  let body: FormData | T | any;

  if (
    domain instanceof Campaign ||
    domain instanceof CampaignEdit ||
    domain instanceof Article
  ) {
    body = domain.toJsonData();
  } else if (
    domain instanceof MediaObject ||
    domain instanceof RefreshSession
  ) {
    body = domain.toFormData();
  } else {
    body = domain.clone() as T;
    body.cleanse(true);

    // We do need the MessageId for setMessageRead-method
    if (!(domain instanceof Message)) {
      delete body.id;
    }

    // endpoint needs gender id as string
    if (domain instanceof CodeDispatch) {
      // @ts-ignore
      if (body.hasOwnProperty('customerData')) {
        body.customerData.gender = body.customerData.gender.id;
      }
      if (body.hasOwnProperty('campaign')) {
        body['campaignId'] = body['campaign'].id;
        delete body['campaign'];
      }
    }

    restify(body);
  }

  let request;
  switch (method) {
    case 'POST':
      request = post(
        { commit, dispatch },
        fetch,
        url,
        body,
        shouldRequestRefreshToken,
        suppressClientErrorPopups
      );
      break;
    case 'PUT':
      request = put({ commit, dispatch }, fetch, url, body);
      break;
    case 'PATCH':
      request = patch({ commit, dispatch }, fetch, url, body);
      break;
    case 'DELETE':
      request = del({ commit, dispatch }, fetch, url, body);
      break;
    default:
      break;
  }

  let response;
  try {
    response = await request;
    if (response && response.hasOwnProperty('@id')) {
      response.id = response['@id'];
    }
  } catch (error) {
    if (error.response) {
      // handle custom descriptions for client error responses from api-platform
      const errorObject = await error.response.json();
      if (
        errorObject['@type'] === 'hydra:Error' &&
        errorObject.hasOwnProperty('hydra:description')
      ) {
        throw errorObject['hydra:description'];
      }

      await handleAPIValidationsWithDomain<T>(error, errorObject, domain);
    }
    console.warn(error);
    throw error;
  }
  if (mutation) {
    const normalizedData = normalizeData([domain]);
    commit(mutation, normalizedData);
  }

  return response;
};

export default pushSingle;
