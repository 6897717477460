import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import Campaign from '@/domains/campaign/Campaign';
import CampaignEdit from '@/domains/campaign/CampaignEdit';
import PopupPayload from '@/types/PopupPayload';

export interface CampaignEditSinglePayload extends PushSinglePayload<Campaign> {
  originalDomain: Campaign;
}

const editCampaign: PushSingleAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  payload: CampaignEditSinglePayload,
): Promise<Campaign> => {
  const { fetch, domain, originalDomain } = payload;
  const { id } = domain;

  const newDomain = CampaignEdit.create(domain);
  const compareActiveInDomain = CampaignEdit.fromChangedCampaign(originalDomain, domain);

  // Remove the option to change date on disabled or to disable campaign
  if (
    newDomain.endDate && !domain.active &&
    (newDomain.endDate.toISOString() !== domain.endDate.toISOString())
  ) {
    setTimeout(() => {
      dispatch('displayPopup', {
        type: 'hint',
        buttonText: 'OK',
        title: 'Nicht erlaubt',
        description: 'Es können nur aktive Kampagnen verlängert werden.',
      } as PopupPayload);
    }, 0);

    throw new Error('Es können nur aktive Kampagnen verlängert werden.');
  }

  // Validate activation constraints
  if (rootGetters.isMandant && newDomain.active) {
    // only skon users can activate campaigns in general
    setTimeout(() => {
      dispatch('displayPopup', {
        type: 'hint',
        buttonText: 'OK',
        title: 'Unzureichende Berechtigungen',
        description: 'Kampagnen können nur durch S-Kon Mitarbeiter aktiviert werden.'
          + ' Wenden Sie sich bitte an Ihren Ansprechpartner.',
      } as PopupPayload);
    }, 0);

    throw new Error('Kampagnen können nur durch S-Kon Mitarbeiter aktiviert werden.');
  }
  if (!rootGetters.isMandant && compareActiveInDomain.active === false) {
    // only mandants can deactivate their campaigns
    setTimeout(() => {
      dispatch('displayPopup', {
        type: 'hint',
        buttonText: 'OK',
        title: 'Unzureichende Berechtigungen',
        description: 'Kampagnen können nur durch Mandanten deaktiviert werden.',
      } as PopupPayload);
    }, 0);

    throw new Error('Kampagnen können nur durch Mandanten deaktiviert werden.');
  }

  const pushPayload = { fetch, domain: newDomain };

  try {
    const response =  await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
      url: `/campaigns/${id}`,
      mutation: undefined,
    });

    commit('setCampaign', domain);

    return response;
  } catch (error) {
    domain.setErrors(newDomain.getErrors());
    throw error;
  }
};

export default editCampaign;
