import fetchList, { FetchListAction, FetchListPayload } from '@/utils/api-platform/fetchList';
import CampaignArticle from '@/domains/campaign/CampaignArticle';

// TODO: should this be renamed?
// it is not really fetching PortfolioArticles but
// differs from fetchCampaignArticleList by the mandant based route
const fetchCampaignPortfolioArticleList: FetchListAction<CampaignArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/articles`,
    domainParser: CampaignArticle.create,
    listMutation: 'setCampaignArticles',
    listInfoMutation: 'setCampaignArticleListInfo',
  });
};

export default fetchCampaignPortfolioArticleList;
