import MinimalArticleVariant from '@/domains/article/MinimalArticleVariant';
import fetchList, {
  FetchListPayload,
  FetchListAction,
} from '@/utils/api-platform/fetchList';

const fetchCategoryVariantList: FetchListAction<MinimalArticleVariant> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: '/variants',
    domainParser: MinimalArticleVariant.create,
    listMutation: 'setListCategoryVariants',
    listInfoMutation: 'setCategoryVariantListInfo',
  });
};

export default fetchCategoryVariantList;
