import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';
import Campaign from '@/domains/campaign/Campaign';
import CampaignAdditionalInfo from '@/domains/campaign/CampaignAdditionalInfo';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import CampaignAccessProperties from '@/domains/campaign/CampaignAccessProperties';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';


class CampaignEdit extends Campaign {
  public static createAll(campaignEdits: any[]): CampaignEdit[] {
    return campaignEdits.map(CampaignEdit.create);
  }

  public static create(data: any): CampaignEdit {
    if (data.salesProperties) {
      data.salesProperties.customMessagingEnabled =
        !!data.salesProperties.messagingProperties.email.text.templateText;
    }
    let salesProperties;
    if (data.salesProperties instanceof SalesCampaign) {
      salesProperties = SalesCampaign.create(data.salesProperties);
    } else {
      salesProperties = data.salesProperties;
    }
    
    const mergedCodeProps: CampaignCodeProperties = {
      ...data.codeProperties,
      quantityUpdated: data.newCodesGeneratedAt,
    };

    /**groups/userGroups in GET /campaigns/:campaign_id is an array of objects, 
     * but in PUT campaigns/:campaign_id JSONData we should send it as an array of strings containing 'id'*/
    const groupsIdArray = (data.groups || data.userGroups || [])?.map(
      item => typeof item === 'string' ? item : item.id);

    return new CampaignEdit(
      data.campaignType,
      CampaignAccessProperties.create(data.accessProperties || {}),
      data.id,
      data.active,
      data.name,
      CampaignCodeProperties.create(mergedCodeProps || {}),
      data.verification,
      data.tokenDistributionType,
      data.mandantId,
      data.fileUploadActive || false,
      data.startDate && new Date(data.startDate),
      data.endDate && new Date(data.endDate),
      data.landingPageUrl,
      data.costCenter,
      // TODO: wrap this with the creator after refactoring CampaignArticle
      data.campaignArticles,
      !!data.customerFields?.length || false,
      CampaignCustomerField.createAll(data.customerFields || []),
      data.accessImage,
      data.approvalMail,
      data.repeatMail,
      data.mandantName,
      data.fileUploadHint,
      data.verificationInstruction,
      data.verificationDocumentInfo,
      CampaignAdditionalInfo.create(data.additionalInfo || {}),
      groupsIdArray,
      data.externalId,
      SalesCampaign.create(data.salesProperties || {}),
      data.authDataCount
    );
  }

  //to avoid 'Kampagnen können nur durch Mandanten deaktiviert werden.' when active === false, make this comparison
  public static fromChangedCampaign(
    originalCampaign: Campaign,
    changedCampaign: Campaign,
  ): CampaignEdit {

    return this.create({
      active: changedCampaign.active !== originalCampaign.active ? changedCampaign.active : undefined, //true OR undefined
    });
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CampaignEdit {
    return CampaignEdit.create(JSON.parse(JSON.stringify(this)));
  }

  public toJsonData() {
    const {
      id,
      mandantId,
      mandantName,
      active,
      authDataCount,
      codeProperties,
      campaignType,
      salesProperties
    } = this;

    let data = super.toJsonData();

   if (active !== undefined && active !== null) {
      data['active'] = active;
    }

   if(id){
    data['id'] = id;
   }

   if(mandantId){
    data['mandantId'] = mandantId;
   }

   if(mandantName){
    data['mandantName'] = mandantName;
   }

   if (codeProperties.updateQuantity) {
    data['codeUpdateQuantity'] = Number(codeProperties.updateQuantity);
  }

   if(authDataCount){
    data['authDataCount'] = authDataCount;
   }

   if (
    data['verificationDocumentInfo'] &&
     data['verificationDocumentInfo']['@id']
   ) {
    data['verificationDocumentInfo'] = data['verificationDocumentInfo']['@id'];
   }

   if (
    data['accessImage'] &&
    data['accessImage']['@id']
   ) {
    data['accessImage'] = data['accessImage']['@id'];
   }

   if (campaignType === 'CAMPAIGN_SALES') {
    if (data['salesProperties']['marketingEndDate']){
      //get only the date part (the first 10 chars), for example: 2023-07-31
      data['salesProperties']['marketingEndDate'] = salesProperties.marketingEndDate.toJSON().slice(0,10);
    }
   }

    return data;
  }
}

export default CampaignEdit;
