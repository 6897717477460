import pushSingle, {
  PushSingleAction,
  PushSinglePayload,
} from '@/utils/api-platform/pushSingle';
import Category from '@/domains/category/Category';

const createCategory: PushSingleAction<Category> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Category>
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/category-portfolios`,
    mutation: 'addCategories',
  });
};

export default createCategory;
