import Login from '@/domains/auth/Login';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import { storeData } from '@/utils/persist';


// TODO: assignableRoleIds Fix
// assignable-role-ids is missing an empty skon array
// Mandant: Contains {"mandant":[...]}
export const fixRolesToAssign = (rolesToAssign) => {
  if (rolesToAssign['skon'] === undefined) {
    rolesToAssign['skon'] = [];
  }
  if (rolesToAssign['mandant'] === undefined) {
    rolesToAssign['mandant'] = [];
  }

  return rolesToAssign;
};

const loginRequest: PushSingleAction<Login> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Login>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = {
    fetch,
    domain,
    shouldRequestRefreshToken: false,
    shouldSetAuthorizationHeader: false,
  };

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/login`,
    mutation: undefined,
  });
  const {
    mandant,
    token,
    refresh_token,
    logout_timestamp,
    roles,
    rolesToAssign,
  } = response;

  storeData('user-token', token);
  storeData('role-ids', roles);
  storeData(
    'assignable-role-ids',
    rolesToAssign && fixRolesToAssign(rolesToAssign) || { skon: [], mandant: [] },
  );
  storeData('notifiable-role-ids', response.notifiableRoles || []);
  storeData('refresh-token', refresh_token);
  storeData('logout-timestamp', Date.parse(logout_timestamp));
  storeData('mandant-id', mandant.id);
  storeData('mandant-name', mandant.name);
  storeData('mandant-shop-host', mandant.shopHost);
  storeData('mandant-modules', mandant.modules);

  commit('setIsUserLoggedIn', true);
  commit('setRoleIds', roles);

  if (rolesToAssign !== undefined) {
    commit('setAssignableRoleIds', fixRolesToAssign(rolesToAssign));
  } else {
    commit('setAssignableRoleIds', {});
  }
  commit('setNotifiableRoleIds', response.notifiableRoles || []);
  commit('setMandantName', mandant.name);
  commit('setMandantId', mandant.id);
  commit('setMandantShopHost', mandant.shopHost);
  commit('setSimulatedMandantId', null);
  commit('setMandantModules', mandant.modules);
  commit('setRefreshToken', refresh_token);
  commit('setLogoutTimestamp', Date.parse(logout_timestamp));
  commit('requestReload');
  return response;
};

export default loginRequest;
