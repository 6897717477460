import pushSingle, { PushSingleAction, PushSingleMandantSpecificPayload } from '@/utils/api-platform/pushSingle';
import User from '@/domains/mandant/User';

const createUser: PushSingleAction<User> = async (
  { commit, dispatch, rootGetters },
  payload: PushSingleMandantSpecificPayload<User>,
) => {
  const { fetch, domain } = payload;
  const pushPayload = { fetch, domain };

  const url = `/users`;

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url,
    mutation: 'addUsers',
  });
};

export default createUser;
