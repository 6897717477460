import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/api-platform/fetchSingle';
import Campaign from '@/domains/campaign/Campaign';


const fetchCampaign: FetchSingleAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `/campaigns/${id}`,
    domainParser: Campaign.create,
    mutation: 'addCampaigns',
  });
};

export default fetchCampaign;
