import { GetterTree } from 'vuex';
import { CampaignState } from './state';
import { ListInfo } from '@/types/ListInfo';
import MinimalCampaign from '@/domains/campaign/MinimalCampaign';
import NormalizedData from '@/types/NormalizedData';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import Campaign from '@/domains/campaign/Campaign';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import SalesCampaignArticleVariant from '@/domains/campaign/SalesCampaignArticleVariant';
import VerificationDocument from '@/domains/order/VerificationDocument';

type CampaignGetter = (id: string) => Campaign;
type CampaignArticleGetter = (id: string) => CampaignArticle;
type SalesCampaignGetter = (id: string) => SalesCampaign;

const getCampaign = (state: CampaignState): CampaignGetter => {
  return (id: string): Campaign => {
    return state.campaigns[id];
  };
};

const getSalesCampaign = (state: CampaignState): SalesCampaignGetter => {
  return (id: string): SalesCampaign => {
    return state.salesCampaigns[id];
  };
};

const getSalesCampaigns = (state: CampaignState): NormalizedData<SalesCampaign> => {
  return state.salesCampaigns;
};

export const getMinimalCampaigns = (state: CampaignState): NormalizedData<MinimalCampaign> => {
  return state.minimalCampaigns;
};

export const getCampaignListInfo = (state: CampaignState): ListInfo => {
  return state.listInfo;
};

export const getCampaignArticles = (state: CampaignState): NormalizedData<CampaignArticle> => {
  return state.campaignArticles;
};

export const getSortedCampaignArticles = (state: CampaignState): CampaignArticle[]=> {
  return Object.values(state.campaignArticles).sort((a, b)=> a.position-b.position);
};

export const getCampaignArticle = (
  state: CampaignState,
): CampaignArticleGetter => (id: string): CampaignArticle => {
  return state.campaignArticles[id];
};

export const getCampaignArticleListInfo = (state: CampaignState): ListInfo => {
  return state.campaignArticleListInfo;
};

export const getSalesCampaignArticleVariants = (state: CampaignState): NormalizedData<SalesCampaignArticleVariant> => {
  return state.salesCampaignArticleVariants;
};

export const getCurrentEditingCampaign = (state: CampaignState): Campaign => {
  return state.currentEditingCampaign;
};

export const getCurrentEditingCampaignMessagesEnabled = (state: CampaignState): boolean => {
  return state.currentEditingCampaign.salesProperties.customMessagingEnabled;
};

export const getCurrentSalesCampaign = (state: CampaignState): Campaign => {
  return state.currentSalesCampaign;
};

export const getVerificationExampleDocument = (state: CampaignState): VerificationDocument => state.verificationExampleDocument;

const getters: GetterTree<CampaignState, any> = {
  getCampaign,
  getSalesCampaign,
  getSalesCampaigns,
  getMinimalCampaigns,
  getCampaignListInfo,
  getCampaignArticles,
  getSortedCampaignArticles,
  getCampaignArticle,
  getCampaignArticleListInfo,
  getCurrentEditingCampaign,
  getCurrentEditingCampaignMessagesEnabled,
  getCurrentSalesCampaign,
  getSalesCampaignArticleVariants,
  getVerificationExampleDocument
};

export default getters;
