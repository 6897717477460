import Domain from '@/types/Domain';
import { CampaignVerification } from '@/types/CampaignVerification';
import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';
import CodeType from '@/types/CodeType';
import CampaignArticle from './CampaignArticle';
import CampaignAdditionalInfo from '@/domains/campaign/CampaignAdditionalInfo';
import displayDate from '@/utils/displayDate';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import CampaignAccessProperties from '@/domains/campaign/CampaignAccessProperties';

interface Props {
  campaignType: 'CAMPAIGN_DEFAULT' | 'CAMPAIGN_SALES' | 'CAMPAIGN_ACCESS';
  accessProperties: CampaignAccessProperties;
  id: string;
  active: boolean;
  name: string;
  codeProperties: CampaignCodeProperties;
  verification: CampaignVerification;
  tokenDistributionType: 'DOWNLOAD' | 'ON_DEMAND';
  mandantId: string;
  fileUploadActive: boolean;
  startDate: Date;
  endDate: Date;
  landingPageUrl: string;
  costCenter: string;
  campaignArticles: CampaignArticle[];
  customerFields: CampaignCustomerField[];
  customerFieldsEnabled?: boolean;
  approvalMail?: string;
  repeatMail?: string;
  mandantName?: string;
  fileUploadHint?: string;
  verificationInstruction?: string;
  verificationDocumentInfo?: string;
  additionalInfo?: CampaignAdditionalInfo;
  groups: string[];
  externalId?: string;
  salesProperties?: SalesCampaign;
  authDataCount?: number;
  accessImage?: string;
}

class Campaign extends Domain implements Props {
  public static createAll(campaigns: any[]): Campaign[] {
    return campaigns.map(Campaign.create);
  }

  public static create(data: any): Campaign {
    const mergedCodeProps: CampaignCodeProperties = {
      ...data.codeProperties,
      quantityUpdated: data.newCodesGeneratedAt,
    };

    /**groups/userGroups in GET /campaigns/:campaign_id is an array of objects, 
     * but we need it as an array of strings containing 'id'*/
    const groupsIdArray = (data.groups || data.userGroups || [])?.map(
      item => typeof item === 'string' ? item : item.id);

    return new Campaign(
      data.campaignType,
      CampaignAccessProperties.create(data.accessProperties || {}),
      data.id,
      typeof data.active === 'boolean'
        ? (data.active as boolean)
        : !!parseInt(data.active),
      data.name,
      CampaignCodeProperties.create(mergedCodeProps || {}),
      data.verification,
      data.tokenDistributionType,
      data.mandantId,
      data.fileUploadActive || false,
      data.startDate && new Date(data.startDate),
      data.endDate && new Date(data.endDate),
      data.landingPageUrl,
      data.costCenter,
      // TODO: wrap this with the creator after refactoring CampaignArticle
      data.campaignArticles,
      !!data.customerFields?.length || false,
      CampaignCustomerField.createAll(data.customerFields || []),
      data.accessImage,
      data.approvalMail,
      data.repeatMail,
      data.mandantName,
      data.fileUploadHint,
      data.verificationInstruction,
      data.verificationDocumentInfo,
      CampaignAdditionalInfo.create(data.additionalInfo || {}),
      groupsIdArray,
      data.externalId,
      SalesCampaign.create(data.salesProperties || {}),
      data.authDataCount
    );
  }

  protected constructor(
    public campaignType:
      | 'CAMPAIGN_DEFAULT'
      | 'CAMPAIGN_SALES'
      | 'CAMPAIGN_ACCESS',
    public accessProperties: CampaignAccessProperties,
    public id: string,
    public active: boolean,
    public name: string,
    public codeProperties: CampaignCodeProperties,
    public verification: CampaignVerification,
    public tokenDistributionType: 'DOWNLOAD' | 'ON_DEMAND' = 'DOWNLOAD',
    public mandantId: string,
    public fileUploadActive: boolean,
    public startDate: Date,
    public endDate: Date,
    public landingPageUrl: string,
    public costCenter: string,
    public campaignArticles: CampaignArticle[],
    public customerFieldsEnabled: boolean,
    public customerFields: CampaignCustomerField[],
    public accessImage?: string,
    public approvalMail?: string,
    public repeatMail?: string,
    public mandantName?: string,
    public fileUploadHint?: string,
    public verificationInstruction?: string,
    public verificationDocumentInfo?: string,
    public additionalInfo?: CampaignAdditionalInfo,
    public groups: string[] = [],
    public externalId?: string,
    public salesProperties?: SalesCampaign,
    public authDataCount?: number
  ) {
    super();
  }

  get marketingEndDateMin(): Date {
    // cloning the startDate
    let startDate = new Date(this.startDate);
    if (typeof this.startDate === 'string') {
      startDate = new Date(startDate);
    }
    startDate = new Date(Number(startDate));
    startDate.setDate(startDate.getDate() + 1);
    return startDate;
  }

  get startDateMin(): Date {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 1);
    startDate.setHours(0);
    return startDate;
  }

  get endDateMin(): Date {
    let startDate;
    if (this.startDate === null) {
      startDate = new Date();
    } else {
      startDate = new Date(this.startDate);
      startDate = this.assureDateType(startDate);
      startDate.setDate(startDate.getDate() + 1);
      startDate.setHours(0);
    }
    return startDate;
  }

  get endDateMax(): Date {
    let startDate;
    if (this.startDate === null) {
      startDate = new Date();
    } else {
      startDate = new Date(this.startDate);
      startDate = this.assureDateType(startDate);
      startDate.setMonth(startDate.getMonth() + 6);
      startDate.setHours(0);
    }
    return startDate;
  }

  get marketingEndDateMax(): Date {
    let endDate = new Date(this.endDate);
    endDate = this.assureDateType(endDate);
    endDate = new Date(Number(endDate));
    endDate.setDate(endDate.getDate() - 30);
    return endDate;
  }

  get marketingEndDateMinFormatted(): string {
    return displayDate(this.marketingEndDateMin);
  }

  get marketingEndDateMaxFormatted(): string {
    return displayDate(this.marketingEndDateMax);
  }

  get redemptionDelayMax(): number {
    let endDate = new Date(this.endDate);
    let x = 30;
    endDate = this.assureDateType(endDate);
    let lowerBoundaryDate = this.startDate;
    if (this.salesProperties.marketingEndDate) {
      lowerBoundaryDate = this.salesProperties.marketingEndDate;
    }
    lowerBoundaryDate = this.assureDateType(lowerBoundaryDate);

    x =
      (endDate.getTime() - lowerBoundaryDate.getTime()) / (1000 * 3600 * 24) -
      22;

    if (x > 30) {
      x = 30;
    }

    return x;
  }

  get redemptionDelayMaxFormatted(): string {
    const x = this.redemptionDelayMax;
    if (x > 1) {
      return '' + x + ' Tage';
    } else {
      return '1 Tag';
    }
  }

  public correctArticlePositionIfNeeded(){
    if ( !Array.isArray(this.campaignArticles) ){
          this.campaignArticles = [];
    }

    const isSomeArticlePositionUndefined = this.campaignArticles.some((campaignArticle)=> !Boolean(campaignArticle.position));
    if (isSomeArticlePositionUndefined){
      this.campaignArticles = this.campaignArticles.map((campaignArticle, index) => {
        campaignArticle.position= index + 1;
        return campaignArticle;
      })
    }
  }

  public constraintsCustomerFields(): {} {
    const applyConstraintsFromArray = (arr: Domain[], path: string) => {
      const newConstraints = {};
      arr.forEach((el: Domain, index: number) => {
        const constraints = el.constraints();
        Object.keys(constraints).forEach(
          (key: string) =>
            (newConstraints[`${path}.${index}.${key}`] = constraints[key])
        );
      });
      return newConstraints;
    };
    return applyConstraintsFromArray(this.customerFields, 'customerFields');
  }

  public constraints(): {} {
    return {
      /**  instead of this, we will have File.size
      'verificationDocuments.size': {
        numericality: {
          greaterThan: 0,
          lessThanOrEqualTo: 10489000,
          message: '^Datei darf höchstens 10MB groß sein.',
        },
      },
      */
      fileUploadHint: {
        length: {
          minimum: 3,
          maximum: 1000,
          tooShort: '^Muss mindestens 3 Zeichen lang sein',
          tooLong: '^Darf maximal 1000 Zeichen lang sein',
        },
      },
      verificationInstruction: {
        length: {
          minimum: 3,
          maximum: 1000,
          tooShort: '^Muss mindestens 3 Zeichen lang sein',
          tooLong: '^Darf maximal 1000 Zeichen lang sein',
        },
      },
      description: {
        length: {
          minimum: 3,
          message: '^Muss mindestens 3 Zeichen enthalten.',
        },
      },
      approvalMail: {
        email: {
          message: '^Muss eine valide Email Adresse sein.',
        },
      },
    };
  }

  public clone(): Campaign {
    const clone = JSON.parse(JSON.stringify(this));
    const recreated = Campaign.create({
      ...clone,
    });
    recreated.accessImage = this.accessImage;
    return recreated;
  }

  public toJsonData() {
    const {
      campaignType,
      accessProperties,
      startDate,
      endDate,
      landingPageUrl,
      approvalMail,
      costCenter,
      codeProperties,
      customerFields,
      verification,
      verificationInstruction,
      verificationDocumentInfo,
      campaignArticles,
      fileUploadActive,
      fileUploadHint,
      additionalInfo,
      groups,
      externalId,
      salesProperties,
      customerFieldsEnabled,
      accessImage,
    } = this;

    let data = {};

    Object.getOwnPropertyNames(this).forEach((key) => {
      const excludedProperties = [
        'errors',
        'campaignType',
        'accessProperties',
        'accessImage',
        'id',
        '__ob__',
        'campaignArticles',
        'verificationInstruction',
        'verificationDocumentInfo',
        'customerFields',
        'mandantId',
        'mandantName',
        'approvalMail',
        'repeatMail',
        'startDate',
        'endDate',
        'landingPageUrl',
        'codeProperties',
        'costCenter',
        'fileUploadActive',
        'fileUploadHint',
        'additionalInfo',
        'groups',
        'externalId',
        'salesProperties',
        'active',
        'customerFieldsEnabled',
        'authDataCount',
        'toJsonData',
      ];

      if (excludedProperties.indexOf(key) === -1) {
        data[key] = (this as any)[key];
      }
    });

    data['campaignType'] = campaignType;
    data['startDate'] = new Date(startDate).toISOString();
    data['endDate'] = new Date(endDate).toISOString();
    data['codeProperties'] = {
      type: codeProperties.type.id,
    };
    data['campaignType'] = campaignType;

    if (this.campaignType === 'CAMPAIGN_ACCESS') {
      data['tokenDistributionType'] = 'ON_DEMAND';
      data['accessImage'] = accessImage;
      data['accessProperties'] = {
        customErrorMessage: accessProperties.customErrorMessage,
      };

      if (accessProperties.displayName)
        data['accessProperties']['displayName'] = accessProperties.displayName;
      if (accessProperties.description)
        data['accessProperties']['description'] = accessProperties.description;

      if (accessProperties.columnInformations) {
        data['accessProperties']['columnInformations'] =
          accessProperties.columnInformations?.map((customerField) => ({
            name: customerField.name,
            description: customerField.description,
            regExp: customerField.regExp,
            caseSensitive: customerField.caseSensitive,
          }));
      }
    }

    if (
      codeProperties.type.id === CodeType.SINGLE_USAGE.id ||
      codeProperties.type.id === CodeType.GENERIC_LIMITED.id
    ) {
      if (campaignType !== 'CAMPAIGN_ACCESS') {
        data['codeProperties']['quantity'] = Number(codeProperties.quantity);
      }
    }

    if (verification === 'APPROVAL_BY_USER') {
      if (approvalMail) {
        data['approvalMail'] = approvalMail;
      }
    }

    data['fileUploadActive'] = fileUploadActive;
    if (fileUploadActive) {
      data['fileUploadHint'] = fileUploadHint;

      if (verificationDocumentInfo) {
        data['verificationDocumentInfo'] = verificationDocumentInfo;
      }
      if (verificationInstruction) {
        data['verificationInstruction'] = verificationInstruction;
      }
    }

    if (additionalInfo.additionalInfoConfirm) {
      data['additionalInfo'] = {
        text: additionalInfo.additionalInfoText,
        confirm: additionalInfo.additionalInfoConfirm,
      };
    }

    if (landingPageUrl) {
      data['landingPageUrl'] = landingPageUrl;
    }

    if (costCenter) {
      data['costCenter'] = costCenter;
    }

    if (externalId) {
      data['externalId'] = externalId;
    }

    if (campaignType === 'CAMPAIGN_SALES') {
      if (groups) {
        data['userGroups'] = groups;
      }
      data['salesProperties'] = {};
      if (salesProperties.redemptionDelay) {
        data['salesProperties']['redemptionDelay'] = Number(
          salesProperties.redemptionDelay
        );
      }

      data['salesProperties']['description'] = salesProperties.description;
      if (salesProperties.customMessagingEnabled) {
        data['salesProperties']['messagingProperties'] = {
          email: {
            senderName: salesProperties.messagingProperties.email.senderName,
            subject: salesProperties.messagingProperties.email.subject,
            text: salesProperties.messagingProperties.email.text.templateText,
          },
          sms: {
            senderName: salesProperties.messagingProperties.sms.senderName,
            text: salesProperties.messagingProperties.sms.text.templateText,
          },
        };
      }

      if (salesProperties.marketingEndDate) {
          data['salesProperties']['marketingEndDate'] = salesProperties.marketingEndDate;
        }
    }

    if (customerFieldsEnabled) {
      data['customerFields'] = customerFields.map(
        (customerField: CampaignCustomerField) => ({
          name: customerField.name,
          required: customerField.required,
          type: customerField.type,
          description: customerField.description,
          regExp: customerField.regExp,
        })
      );
    }

    if (campaignArticles) {
      data['campaignArticles'] = campaignArticles.map(
        (campaignArticle: CampaignArticle) => ({
          articleId: campaignArticle.article.id,
          articlePosition: campaignArticle.position,
          coPaymentGrossC2B:
            campaignArticle.coPaymentGrossC2B.amount.toString(),
        })
      );
    }
    
    return data;
  }

  private assureDateType(date): Date {
    if (typeof date === 'string') {
      return new Date(date);
    } else {
      return date;
    }
  }
}

export default Campaign;
