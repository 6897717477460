import Domain from '@/types/Domain';
import Article from './Article';
import Image from './Image';

interface Props {
  id: string;
  description: string;
  order: number;
  image?: Image;
  isNew?: boolean;
}

class ArticleImage extends Domain implements Props {
  public static createAll(articles: any[]): ArticleImage[] {
    return articles.map(ArticleImage.create);
  }

  public static create(data: any): ArticleImage {
    return new ArticleImage(
      data.id,
      data.description,
      data.sort || data.order,
      data.image,
      data.isNew
    );
  }

  private constructor(
    public id: string,
    public description: string,
    public order: number,
    public image: Image,
    public isNew: boolean = false
  ) {
    super();
  }

  public constraints(): {} {
    return {
      description: {
        format: {
          pattern: /^$|^.{2,100}$/,
          message: '^Beschreibung muss zwischen 2 und 100 Zeichen lang sein',
        },
      },
    };
  }

  public clone(): ArticleImage {
    const clone = JSON.parse(JSON.stringify(this));
    return ArticleImage.create({
      ...clone,
    });
  }
}

export default ArticleImage;
