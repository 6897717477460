import fetchList, { FetchListAction, FetchListPayload } from '@/utils/api-platform/fetchList';
import TagArticleCount from '@/domains/tag/TagArticleCount';

const fetchPortfolioTagList: FetchListAction<TagArticleCount> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  const url =  `/portfolio-tags`;

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: TagArticleCount.create,
    listMutation: 'setTagArticleCounts',
    listInfoMutation: 'setTagArticleCountListInfo',
  });
};

export default fetchPortfolioTagList;
