import Domain from '@/types/Domain';
import Category from '@/domains/category/Category';
import Manufacturer from '@/domains/manufacturer/Manufacturer';
import ArticleImage from '@/domains/article/ArticleImage';
import sortByProp from '@/utils/sortByProp';
import Money from '../common/Money';

interface Props {
  id: string;
  active: boolean;
  name: string;
  manufacturer: Manufacturer;
  categorySpecificPortfolio: Category;
  variantCount: number;
  articlePrice: Money;
  unitFee: Money;
  isArticleInCampaignActive: boolean;
  images: ArticleImage[];
}

class MinimalArticle extends Domain implements Props {
  public static createAll(articles: any[]): MinimalArticle[] {
    return articles.map(MinimalArticle.create);
  }

  public static create(data: any): MinimalArticle {
    return new MinimalArticle(
      data.id,
      data.active || false,
      data.name,
      data.manufacturer || {},
      data.categorySpecificPortfolio || {},
      data.variantCount,
      Money.create(data.articlePrice || {}),
      Money.create(data.unitFee || {}),
      data.isArticleInCampaignActive,
      ArticleImage.createAll(sortByProp(data.images || [], 'order'))
    );
  }

  private constructor(
    public id: string,
    public active: boolean,
    public name: string,
    public manufacturer: Manufacturer,
    public categorySpecificPortfolio: Category,
    public variantCount: number,
    public articlePrice: Money,
    public unitFee: Money,
    public isArticleInCampaignActive: boolean,
    public images: ArticleImage[]
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): MinimalArticle {
    const clone = JSON.parse(JSON.stringify(this));
    return MinimalArticle.create({
      ...clone,
      images: this.images.map((image) => image.clone()),
    });
  }
}

export default MinimalArticle;
