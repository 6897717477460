import fetchList, { FetchListAction, FetchListPayload, FetchMandantSpecificListPayload } from '@/utils/api-platform/fetchList';
import MinimalUser from '@/domains/mandant/MinimalUser';

const fetchUserList: FetchListAction<MinimalUser> = async (
  { commit, dispatch, rootGetters },
  fetchUserListPayload: FetchMandantSpecificListPayload,
) => {
  const {
    fetch,
    page,
    limit,
    filters,
    sorts,
  } = fetchUserListPayload;
  const fetchListPayload: FetchListPayload = {
    fetch,
    page,
    limit,
    filters,
    sorts,
  };

  const url = `/users`;

  return await fetchList({ commit, dispatch }, fetchListPayload, {
      url,
      domainParser: MinimalUser.create,
      listMutation: 'setMinimalUsers',
      listInfoMutation: 'setUserListInfo',
    });
};

export default fetchUserList;
