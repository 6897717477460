import fetchList, { FetchListAction, FetchListPayload } from '@/utils/api-platform/fetchList';
import CampaignArticle from '@/domains/campaign/CampaignArticle';

export interface FetchCampaignArticleListPayload extends FetchListPayload {
  campaignId: string;
}

const fetchCampaignArticleList: FetchListAction<CampaignArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchCampaignArticleListPayload,
) => {
  const { campaignId } = fetchListPayload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/campaigns/${campaignId}/campaign-articles`;
  } else {
    url = `/campaigns/${campaignId}/campaign-articles`;
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: CampaignArticle.create,
    listMutation: 'setCampaignArticles',
    listInfoMutation: 'setCampaignArticleListInfo',
  });
};

export default fetchCampaignArticleList;
