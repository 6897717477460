import fetchSingle, { FetchSinglePayload } from '@/utils/fetchSingle';
import VerificationDocument from '@/domains/order/VerificationDocument';


interface FetchSingleVerificationDocumentPayload extends FetchSinglePayload {
  index: number;
}

type FetchSingleVerificationDocumentAction = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: FetchSingleVerificationDocumentPayload,
) => Promise<VerificationDocument>;

const fetchCampaignVerificationDocument: FetchSingleVerificationDocumentAction = async (
  { commit, dispatch, rootGetters },
  payload: FetchSingleVerificationDocumentPayload,
) => {
  const { id, index } = payload;

  const url = rootGetters.isMandant ? '/my' : '';
  return await fetchSingle({ commit, dispatch }, payload, {
    url: `${url}/campaign/${id}/verification/document/`,
    domainParser: VerificationDocument.create,
    mutation: undefined,
  });

};

export default fetchCampaignVerificationDocument;
