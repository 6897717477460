import fetchSingle, {
  FetchSingleAction,
  FetchSinglePayload,
} from '@/utils/api-platform/fetchSingle';
import Article from '@/domains/article/Article';

const fetchArticle: FetchSingleAction<Article> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload
) => {
  const { id } = payload;

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `/articles/${id}`,
    domainParser: Article.create,
    mutation: 'addArticles',
  });
};

export default fetchArticle;
