import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import Manufacturer from '@/domains/manufacturer/Manufacturer';

const createManufacturer: PushSingleAction<Manufacturer> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Manufacturer>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/manufacturers`,
    mutation: 'addManufacturers',
  });
};

export default createManufacturer;
