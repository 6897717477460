import fetchSingle, { FetchSinglePayload, FetchSingleAction } from '@/utils/api-platform/fetchSingle';
import Tag from '@/domains/tag/Tag';


const fetchTagList: FetchSingleAction<Tag> = async (
  { commit, dispatch },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `/tags/${id}`,
    domainParser: Tag.create,
    mutation: 'addTags',
  });
};

export default fetchTagList;
