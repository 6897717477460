import Domain from '@/types/Domain';
import ArticleImage from '@/domains/article/ArticleImage';
import Money from '@/domains/common/Money';
import sortByProp from '@/utils/sortByProp';

interface Props {
  id: string;
  image: ArticleImage;
  name: string;
  articlePrice: Money;
}

class HighlightArticle extends Domain implements Props {
  public static createAll(highlightArticles: any[]): HighlightArticle[] {
    return highlightArticles.map(HighlightArticle.create);
  }

  public static create(data: any): HighlightArticle {
    const id = data.article?.id || data?.id;
    const articleImages = data.article?.articleImages || data?.images;
    const name = data.article?.name || data?.name;
    const articlePrice = data.article?.articlePrice || data?.articlePrice;
    return new HighlightArticle(
      id,
      ArticleImage.create(
        articleImages && articleImages.length > 0
          ? sortByProp(articleImages, 'order')[0]
          : {}
      ),
      name,
      Money.create(articlePrice || {})
    );
  }

  private constructor(
    public id: string,
    public image: ArticleImage,
    public name: string,
    public articlePrice: Money
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): HighlightArticle {
    return HighlightArticle.create(this);
  }
}

export default HighlightArticle;
