import PasswordReset from '@/domains/auth/PasswordReset';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const passwordResetRequest: PushSingleAction<PasswordReset> = async (
  { commit, dispatch },
  payload: PushSinglePayload<PasswordReset>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = {
    fetch,
    domain,
    shouldRequestRefreshToken: false,
  };

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/password/request`,
    mutation: undefined,
  });

  return response;
};

export default passwordResetRequest;
