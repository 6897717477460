import RefreshSession from '@/domains/auth/RefreshSession';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import { getData, storeData } from '@/utils/persist';


const refreshSessionRequest: PushSingleAction<RefreshSession> = async (
  { commit, dispatch },
  payload: PushSinglePayload<RefreshSession>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = {
    fetch,
    domain,
    shouldSetAuthorizationHeader: false,
    shouldRequestRefreshToken: true,
    refresh_token: getData('refresh-token'),
  };

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/login/refresh`,
    mutation: undefined,
  });
  const {
    token,
    refresh_token,
    logout_timestamp,
  } = response;

  storeData('user-token', token);
  storeData('refresh-token', refresh_token);
  storeData('logout-timestamp', Date.parse(logout_timestamp));
  commit('setRefreshToken', refresh_token);
  commit('setLogoutTimestamp', Date.parse(logout_timestamp));
  return response;
};

export default refreshSessionRequest;
