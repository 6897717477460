import fetchList, {
  FetchListAction,
  FetchListPayload,
} from '@/utils/api-platform/fetchList';
import HighlightArticle from '@/domains/highlight/HighlightArticle';

export interface FetchHighlightsListPayload extends FetchListPayload {
  mandantId?: string;
  dontSave?: boolean;
}

const fetchHighlightsList: FetchListAction<HighlightArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchHighlightsListPayload
) => {
  const { dontSave } = fetchListPayload;

  return await fetchList(
    { commit, dispatch },
    fetchListPayload,
    {
      url: '/highlights',
      domainParser: HighlightArticle.create,
      listMutation: !dontSave && 'setHighlights',
    },
    true
  );
};

export default fetchHighlightsList;
