import pushSingle, { PushSingleAction, PushSingleMandantSpecificPayload } from '@/utils/api-platform/pushSingle';
import Campaign from '@/domains/campaign/Campaign';
import placeHolderImage from '@/assets/placeholder.svg';


const createCampaign: PushSingleAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  payload: PushSingleMandantSpecificPayload<Campaign>,
) => {
  const { fetch, domain } = payload;
  domain.correctArticlePositionIfNeeded();
  const pushPayload = { fetch, domain };

  const domainClone = domain.clone();
  // @ts-ignore
  domainClone.codeProperties.type = domainClone.codeProperties.type.id;

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/campaigns`,
    mutation: 'addCampaigns',
  });

  commit('setTextFields', []);
  commit('setAccessImagePreview', placeHolderImage);

  return response;
};

export default createCampaign;
