import Domain from '@/types/Domain';
import Article from '@/domains/article/Article';
import Money from '@/domains/common/Money';

// TODO: maybe refactor this whole model generally
interface Props {
  id: string;
  article: Article;
  coPaymentGrossC2B: Money;
  articlePriceB2B: Money;
  active: boolean;
  position: number;
  articlePrice: Money;
  taxRate: number;
  unitFee: Money;
}

class CampaignArticle extends Domain implements Props {
  public static createAll(data: any[]): CampaignArticle[] {
    return data.map(CampaignArticle.create);
  }

  public static create(data: any): CampaignArticle {
    return new CampaignArticle(
      data.id,
      Article.create(data.article ? data.article : data),
      Money.create(data.coPaymentGrossC2B || {}),
      data.articlePriceB2B,
      data.active,
      data.position,
      Money.create(data.articlePrice || {}),
      data.taxRate !== undefined ? data.taxRate : '',
      Money.create(data.unitFee || {}),
    );
  }

  private constructor(
    public id: string,
    public article: Article,
    public coPaymentGrossC2B: Money,
    public articlePriceB2B: Money,
    public active: boolean,
    public position: number,
    public articlePrice: Money,
    public taxRate: number,
    public unitFee: Money
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): CampaignArticle {
    return CampaignArticle.create(this.article);
  }
}

export default CampaignArticle;
