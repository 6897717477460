import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import ArticleImage from './ArticleImage';

class MinimalArticleVariant extends Domain {
  public static createAll(entries: any[]): MinimalArticleVariant[] {
    return entries.map(MinimalArticleVariant.create);
  }

  public static createDefault(): MinimalArticleVariant {
    return new MinimalArticleVariant();
  }

  public static create(data: any): MinimalArticleVariant {
    return new MinimalArticleVariant(
      data.id,
      data.name,
      data.articlePrice || data.article?.articlePrice,
      data.buyPrice,
      data.unitFee,
      data.categorySpecificPortfolioShortcut ||
        data.article.categorySpecificPortfolio.shortcut,
      data.manufacturerName,
      data.bulletPoints,
      data.description,
      ArticleImage.createAll(data.images || data.variantImages || []),
      data.manufacturerId,
      typeof data.active === 'boolean'
        ? (data.active as boolean)
        : !!parseInt(data.active)
    );
  }

  // @ts-ignore
  public id: string;
  public name: string;
  public articlePrice: Money;
  public buyPrice: Money;
  public unitFee: Money;
  public categoryShortcut: string;
  public manufacturerName: string;
  public bulletPoints: string[];
  public description: string;
  public images: ArticleImage[];
  public manufacturerId: string;
  public active: boolean;

  private constructor(
    id?: string,
    name?: string,
    articlePrice?: Money,
    buyPrice?: Money,
    unitFee?: Money,
    categoryShortcut?: string,
    manufacturerName?: string,
    bulletPoints?: string[],
    description?: string,
    images?: ArticleImage[],
    manufacturerId?: string,
    active?: boolean
  ) {
    super();
    this.id = id || null;
    this.name = name || '';
    this.articlePrice = Money.create(articlePrice || {});
    this.buyPrice = Money.create(buyPrice || {});
    this.unitFee = Money.create(unitFee || {});
    this.categoryShortcut = categoryShortcut || '';
    this.manufacturerName = manufacturerName || '';
    this.bulletPoints = bulletPoints || [];
    this.description = description || '';
    this.images = images || [];
    this.manufacturerId = manufacturerId || '';
    this.active = active || false;
  }

  public clone(): MinimalArticleVariant {
    const deepCopy = (data: {}) => JSON.parse(JSON.stringify(data));

    const variant = new MinimalArticleVariant(
      this.id,
      this.name,
      deepCopy(this.articlePrice),
      deepCopy(this.buyPrice),
      deepCopy(this.unitFee),
      this.categoryShortcut,
      this.manufacturerName,
      deepCopy(this.bulletPoints),
      this.description,
      deepCopy(this.images),
      this.manufacturerId,
      this.active
    );
    variant.errors = this.errors;
    return variant;
  }

  public constraints(): {} {
    // ToDo: add constraints
    return {};
  }
}

export default MinimalArticleVariant;
