import CampaignArticle from '@/domains/campaign/CampaignArticle';
import CampaignArticleEdit from '@/domains/campaign/CampaignArticleEdit';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const editCampaignArticle: PushSingleAction<CampaignArticle> = async (
  { commit, dispatch },
  payload: PushSinglePayload<CampaignArticle>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;
  const editDomain = CampaignArticleEdit.createFromCampaignArticle(domain);
  const pushPayload = { fetch, domain: editDomain };

  return await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
    url: `/campaign-articles/${id}`,
    mutation: undefined,
  });
};

export default editCampaignArticle;
